import React, { useEffect } from "react";
import "../IntakeForm/IntakeForm.scss";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import useAlertUtils from "../../utils/common.utils";
import Personalinformation from "./Personalinformation/Personalinformation";
import History from "./History/History";
import Questions from "./Questions/Questions";
import Idverification from "./Idverification/Idverification";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { fetchData } from "../../services/apiService";
import { useDispatch, useSelector } from "react-redux";
import { updateTab } from "../../redux/slices/tab.slices";

function IntakeForm() {
  //  Tabs
  const [value, setValue] = React.useState("1");
  const navigate = useNavigate();

  const tabState = useSelector((state) => state.tab.value);
  const dispatch = useDispatch();
  const { alertSuccess, alertError } = useAlertUtils();

  // const [pageLoader, setPageLoader] = useState(false);

  const handleChange = (event, newValue) => {
    dispatch(updateTab(newValue));
  };

  async function check() {
    try {
      const response = await fetchData("/api/intakeForms/check/formStatus");
      const procedure = localStorage.getItem("procedure");
      const productId = localStorage.getItem("productId");

      if (response) {
        if (response.data.status === "5") {
          if (procedure && productId) {
            navigate("/app/product-details");
          }
        }
        dispatch(updateTab(response.data.status));
      }
    } catch (error) {
      alertError("Error Fetching Form Status");
    }
  }

  useEffect(() => {
    check();
  }, []);

  return (
    <>
      <Header></Header>
      <div className="main_content intake_form">
        <div className="container">
          <div className="back_link">
            <Link className="action_link" to="/app/lab-results">
              <FaArrowLeft />
              Lab Results
            </Link>
          </div>

          <div className="main_block">
            <div className="top_bar">
              <div className="main_title">
                Medical History Intake Questionnaire
              </div>
            </div>
            <div className="bottom_bar">
              {tabState !== "5" ? (
                <div className="tabs">
                  <Box>
                    <TabContext value={tabState}>
                      <Box>
                        <TabList
                          onChange={handleChange}
                          variant="scrollable"
                          scrollButtons="auto"
                          aria-label="scrollable auto tabs example"
                        >
                          <Tab label="1 Personal Information" value="1" />
                          <Tab label="2 History" value="2" />
                          <Tab label="3 Questions" value="3" />
                          <Tab label="4 Verify your identity" value="4" />
                        </TabList>
                      </Box>
                      <TabPanel value="1">
                        <div className="tabpanel_inner personal_info">
                          <Personalinformation
                            handleChange={handleChange}
                            tabVal={tabState}
                          ></Personalinformation>
                        </div>
                      </TabPanel>

                      <TabPanel value="2">
                        <div className="tabpanel_inner history">
                          <History
                            handleChange={handleChange}
                            tabVal={value}
                          ></History>
                        </div>
                      </TabPanel>

                      <TabPanel value="3">
                        <div className="tabpanel_inner question">
                          <Questions
                            handleChange={handleChange}
                            tabVal={value}
                          ></Questions>
                        </div>
                      </TabPanel>

                      <TabPanel value="4">
                        <div className="tabpanel_inner signature">
                          <Idverification
                            handleChange={handleChange}
                            tabVal={value}
                            check={check}
                          ></Idverification>
                        </div>
                      </TabPanel>
                    </TabContext>
                  </Box>
                </div>
              ) : (
                <div className="alert">
                  <span className="alertText">
                    You have already submitted the Intake Form. Thanks !
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default IntakeForm;
