import React from "react";

import { Link, useNavigate } from "react-router-dom";
import serviceimg5 from "../../assets/img/serviceimg5.svg";
import serviceimg6 from "../../assets/img/serviceimg6.svg";
import serviceimg7 from "../../assets/img/serviceimg7.svg";
import serviceimg8 from "../../assets/img/serviceimg8.svg";
import serviceimg9 from "../../assets/img/serviceimg9.svg";
import serviceimg10 from "../../assets/img/serviceimg10.svg";
import { useFormik } from "formik";
import Footer from "../../components/Footer/Footer";
import PreLoginHeader from "../../components/PreLoginHeader/PreLoginHeader";
import { SignInSchema } from "../../validations/validation";
import {
  decryptedData,
  makeEncryptionData,
} from "../../services/encryptionDecription.service";
import { SubmitData } from "../../services/apiService";
import useAlertUtils from "../../utils/common.utils";
import "./Login.css";
export default function Login() {
  const { alertSuccess, alertError } = useAlertUtils();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: SignInSchema,
    onSubmit: async (values) => {
      try {
        const encryptedData = makeEncryptionData(values);

        if (encryptedData) {
          const response = await SubmitData("/api/users/signin", encryptedData);
          if (response?.success) {
            localStorage.setItem("uFN", response?.data?.firstName);
            localStorage.setItem("uLN", response?.data?.lastName);
            localStorage.setItem("uR", response?.data?.role);
            localStorage.setItem("token", response?.data?.token);

            alertSuccess(response?.message);
            const productURL = localStorage.getItem("productURL");
            if (productURL) {
              navigate(productURL);
              localStorage.removeItem("productURL");
            } else {
              if (decryptedData(response?.data?.role) === "admin" || decryptedData(response?.data?.role) === "super-admin") {
                return navigate("/app/dashboard");
              } else {
                return navigate("/app/lab-results");
              }
            }
          } else {
            alertError(response?.message);
          }
        }
      } catch (error) {
        alertError("An error occurred during login. Please try again.");
      }
    },
  });

  return (
    <>
      <PreLoginHeader></PreLoginHeader>
      <div className="login login_acc">
        <div className="login_inner">
          <div className="row">
            <div className="left_part part col-md-6">
              <div className="leftpart_inner">
                <div className="top_part part">
                  <h3>Welcome BACK to hrt</h3>
                  <h1>Your Path to Optimal Health</h1>
                </div>
                <div className="middle_part part">
                  <h3 className="service_title">
                    Make your healthcare process simpler with
                  </h3>
                  <div className="item_wrapper">
                    <div className="item">
                      <div className="service_img">
                        <img src={serviceimg5} alt="service 5" />
                      </div>
                    </div>
                    <div className="item">
                      <div className="service_img">
                        <img src={serviceimg6} alt="service 6" />
                      </div>
                    </div>
                    <div className="item">
                      <div className="service_img">
                        <img src={serviceimg7} alt="service 7" />
                      </div>
                    </div>
                    <div className="item">
                      <div className="service_img">
                        <img src={serviceimg8} alt="service 8" />
                      </div>
                    </div>
                    <div className="item">
                      <div className="service_img">
                        <img src={serviceimg9} alt="service 9" />
                      </div>
                    </div>
                    <div className="item">
                      <div className="service_img">
                        <img src={serviceimg10} alt="service 10" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right_part part col-md-6">
              <div className="rightpart_inner">
                <div className="login_form">
                  <div className="login_form_inner">
                    {/* Passing handleSubmit parameter tohtml form onSubmit property */}
                    <form noValidate onSubmit={formik.handleSubmit}>
                      <h1 className="login_title">Login Your Account</h1>
                      {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}

                      <div className="form_field_wrapper">
                        <label>Email</label>
                        <input
                          type="email"
                          name="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          placeholder="Enter Email"
                          className="form-control inp_text"
                          id="email"
                        />
                        {/* If validation is not passed show errors */}
                        <p className="error">
                          {formik.errors.email &&
                            formik.touched.email &&
                            formik.errors.email}
                        </p>
                      </div>

                      {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}

                      <div className="form_field_wrapper">
                        <label>Password</label>
                        <input
                          type="password"
                          name="password"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password}
                          placeholder="Enter Password"
                          className="form-control"
                        />
                        {/* If validation is not passed show errors */}
                        <p className="error">
                          {formik.errors.password &&
                            formik.touched.password &&
                            formik.errors.password}
                        </p>
                      </div>

                      <div className="form_field_wrapper forgotpass">
                        <Link className="linkto" to="/recover-password">
                          Forgot Password?
                        </Link>
                      </div>

                      {/* Click on submit button to submit the form */}
                      <button type="submit" className="btn btn-primary">
                        LOGIN
                      </button>

                      <div className="form_field_wrapper signuplink_block">
                        <Link className="linkto signuplink" to="/register">
                          Don't have an account yet?{" "}
                          <span className="signup_txt">SIGN UP</span>
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
