import React from "react";
import { Route, Routes } from "react-router-dom";
import { Auth } from "../components/Auth/Auth";
import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "../components/TermsAndConditions/TermsAndConditions";
import ChangePassword from "../pages/ChangePassword/ChangePassword";
import EmailVerification from "../pages/EmailVerification/EmailVerification";
import IntakeForm from "../pages/IntakeForm/IntakeForm";
import Login from "../pages/Login/Login";
import NewPassword from "../pages/NewPassword/NewPassword";
import RecoverPassword from "../pages/RecoverPassword/RecoverPassword";
import Register from "../pages/Register/Register";
import Report from "../pages/Report/Report";
import Dashboard from "../pages/User/Dashboard";
import ProviderDashboard from "../pages/Provider/Dashboard";
import IntakeData from "../pages/Provider/IntakeData/IntakeData";
import PatientDetails from "../pages/Provider/PatientDetails/PatientDetails";
import Catalogs from "../pages/Provider/Products/Catalogs/Catalog";
import CatalogCreate from "../pages/Provider/Products/Catalogs/CatalogCreate";
import ListPlans from "../pages/Provider/Paypal/Plan/ListPlans/ListPlans";
import Product_Details from "../pages/ProductDetails/ProductDetails";
// import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Checkout from "../pages/Checkout/Checkout";
import Confirmation from "../pages/Confirmation/Confirmation";
import CatalogEdit from "../pages/Provider/Products/Catalogs/CatalogEdit";
import PatientOrder from "../pages/Provider/PatientOrder/PatientOrder";
// import { useSelector } from 'react-redux'
import MyOrder from "../pages/Provider/MyOrder/MyOrder";
import OrderView from "../pages/Provider/PatientOrder/OrderView";
import MyOrderView from "../pages/Provider/MyOrder/MyOrderView";
import NotFound from "../pages/NotFoundPage/NotFound";
import Admins from "../pages/Provider/Admins/admins";
export default function Routers() {
  // const paypalClientKey = process.env.REACT_APP_PAYPAL_CLIENT_KEY;
  //   const cartProduct = useSelector((state) => state.cart);
  return (
    <>
      <Routes>
        <Route path="*" element={<NotFound></NotFound>}></Route>
        <Route path="/" element={<Login></Login>}></Route>
        <Route path="/register" element={<Register></Register>}></Route>
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route
          path="/privacy-policy"
          element={<PrivacyPolicy></PrivacyPolicy>}
        ></Route>
        <Route path="/consent" element={<TermsAndConditions />}></Route>
        <Route path="/recover-password" element={<RecoverPassword />}></Route>
        <Route
          path="/new-password/:token"
          element={<NewPassword></NewPassword>}
        ></Route>
        <Route
          path="/verify/account/:token"
          element={<EmailVerification></EmailVerification>}
        ></Route>

        <Route path="/app" element={<Auth />}>
          <Route path="lab-results" element={<Dashboard></Dashboard>}></Route>
          <Route
            path="medical-history"
            element={<IntakeForm></IntakeForm>}
          ></Route>
          <Route path="report/:kitId" element={<Report></Report>}></Route>
          <Route
            path="change-password"
            element={<ChangePassword></ChangePassword>}
          ></Route>
          <Route path="my-orders" element={<MyOrder></MyOrder>}></Route>
          {/* Provider Routes */}
          <Route
            path="dashboard"
            element={<ProviderDashboard></ProviderDashboard>}
          ></Route>
          <Route
            path="patient-profiles"
            element={<IntakeData></IntakeData>}
          ></Route>
          <Route
            path="patient-details/:id"
            element={<PatientDetails></PatientDetails>}
          ></Route>
          <Route path="catalogs" element={<Catalogs></Catalogs>}></Route>
          <Route
            path="catalogs/add"
            element={<CatalogCreate></CatalogCreate>}
          ></Route>
          <Route
            path="catelogs/edit"
            element={<CatalogEdit></CatalogEdit>}
          ></Route>
          <Route path="list-plans/:productId" element={<ListPlans />}></Route>
          <Route path="product-details" element={<Product_Details />}></Route>
          <Route path="checkout" element={<Checkout />}></Route>
          <Route
            path="confirmation"
            element={<Confirmation></Confirmation>}
          ></Route>
          <Route
            path="patient-order"
            element={<PatientOrder></PatientOrder>}
          ></Route>
          <Route
            path="view-order/:id"
            element={<OrderView></OrderView>}
          ></Route>
          <Route
            path="view-my-order/:id"
            element={<MyOrderView></MyOrderView>}
          ></Route>
          <Route path="admins-list"
          element={<Admins></Admins>}>
          </Route>
        </Route>
      </Routes>
    </>
  );
}
