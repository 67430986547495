import React, { useState, useEffect } from "react";
import "../Questions/Questions.scss";
import FormGroup from "@mui/material/FormGroup";
import { useFormik } from "formik";
// import * as Yup from 'yup';
import { fetchData, SubmitData } from "../../../services/apiService";
import { useDispatch } from "react-redux";
import { updateTab } from "../../../redux/slices/tab.slices";
import useAlertUtils from "../../../utils/common.utils";
// import { makeDecryptionData } from "../../../services/encryptionDecription.service";

const Questions = (props) => {
  const [healthGoals, setHealthGoals] = useState({
    weightLoss: 0,
    libido: 0,
    muscleMass: 0,
    memory: 0,
    energy: 0,
    bodyComposition: 0,
    stress: 0,
    sleep: 0,
  });

  const dispatch = useDispatch();
  const { alertSuccess, alertError } = useAlertUtils();

  const formik = useFormik({
    initialValues: healthGoals,
    onSubmit: async (values) => {
      try {
        const response = await SubmitData(
          "/api/intakeForms/questionnaires",
          values
        );
        if (response.success) {
          alertSuccess(response.message);
          dispatch(updateTab("4"));
        } else {
          alertError(response.message);
        }
      } catch (error) {
        alertError("An error occurred while submitting the data.");
      }
    },
  });

  const handleChange = (key) => {
    const { name, checked } = key.target;
    formik.setFieldValue(name, checked ? 1 : 0);
  };

  useEffect(() => {
    const questionaFunc = async () => {
      try {
        const questiona = await fetchData("/api/intakeForms/questionnaires");

        if (questiona.data !== undefined || questiona.data !== null) {
          formik.setFieldValue("weightLoss", questiona.data.weightLoss);
          formik.setFieldValue("libido", questiona.data.libido);
          formik.setFieldValue("muscleMass", questiona.data.muscleMass);
          formik.setFieldValue("memory", questiona.data.memory);
          formik.setFieldValue("energy", questiona.data.energy);
          formik.setFieldValue(
            "bodyComposition",
            questiona.data.bodyComposition
          );
          formik.setFieldValue("stress", questiona.data.stress);
          formik.setFieldValue("sleep", questiona.data.sleep);
        }
      } catch (error) {}
    };
    questionaFunc();
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <fieldset>
          <div className="form_title">Health Goals</div>
          <div className="question_label">
            Please select all your health goals
          </div>
          <div className="form_group_content">
            <FormGroup>
              <label>
                <input
                  type="checkbox"
                  onBlur={formik.handleBlur}
                  value={formik.values.weightLoss}
                  id="weightLoss"
                  name="weightLoss"
                  onClick={handleChange}
                  checked={formik.values.weightLoss === 1}
                />
                <span> Weight Loss</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  onBlur={formik.handleBlur}
                  value={formik.values.sleep}
                  id="sleep"
                  name="sleep"
                  checked={formik.values.sleep === 1}
                  onClick={handleChange}
                />
                <span> Sleep</span>
              </label>

              <label>
                <input
                  type="checkbox"
                  onBlur={formik.handleBlur}
                  value={formik.values.energy}
                  id="energy"
                  name="energy"
                  checked={formik.values.energy === 1}
                  onClick={handleChange}
                />
                <span> Energy</span>
              </label>

              <label>
                <input
                  type="checkbox"
                  onBlur={formik.handleBlur}
                  value={formik.values.bodyComposition}
                  id="bodyComposition"
                  name="bodyComposition"
                  checked={formik.values.bodyComposition === 1}
                  onClick={handleChange}
                />
                <span> Body Composition</span>
              </label>

              <label>
                <input
                  type="checkbox"
                  onBlur={formik.handleBlur}
                  value={formik.values.stress}
                  id="stress"
                  name="stress"
                  checked={formik.values.stress === 1}
                  onClick={handleChange}
                />
                <span> Stress</span>
              </label>

              <label>
                <input
                  type="checkbox"
                  onBlur={formik.handleBlur}
                  value={formik.values.muscleMass}
                  id="muscleMass"
                  name="muscleMass"
                  checked={formik.values.muscleMass === 1}
                  onClick={handleChange}
                />
                <span> Muscle Mass</span>
              </label>

              <label>
                <input
                  type="checkbox"
                  onBlur={formik.handleBlur}
                  value={formik.values.libido}
                  id="libido"
                  name="libido"
                  checked={formik.values.libido === 1}
                  onClick={handleChange}
                />
                <span> Libido (Sex Drive)</span>
              </label>

              <label>
                <input
                  type="checkbox"
                  onBlur={formik.handleBlur}
                  value={formik.values.mentalAcuity}
                  id="mentalAcuity"
                  name="mentalAcuity"
                  checked={formik.values.mentalAcuity === 1}
                  onClick={handleChange}
                />
                <span> Mental Acuity (Focus)</span>
              </label>

              <label>
                <input
                  type="checkbox"
                  onBlur={formik.handleBlur}
                  value={formik.values.memory}
                  id="memory"
                  checked={formik.values.memory === 1}
                  name="memory"
                  onClick={handleChange}
                />
                <span> Memory</span>
              </label>
            </FormGroup>
          </div>
        </fieldset>

        <hr />
        <div className="primary_btn">
          <input
            className="btn btn-secondary"
            type="button"
            value="previous"
          ></input>
          <button type="submit" className="btn btn-secondary">
            next
          </button>
        </div>
      </form>
    </>
  );
};

export default Questions;
