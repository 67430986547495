import React, { useState } from "react";
import logo from "../../assets/img/logo.svg";
import { Link, NavLink } from "react-router-dom";
import "../Header/Header.scss";

import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { decryptedData } from "../../services/encryptionDecription.service";

const Header = () => {
  // const [open, setOpen] = React.useState(false)

  const [token, setToken] = useState();
  // const [userType, setUserType] = useState('patient')

  let navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("Auth Token");
    localStorage.removeItem("uid");
    localStorage.removeItem("uFN");
    localStorage.removeItem("uLN");
    localStorage.removeItem("uR");
    localStorage.removeItem("procedure");
    localStorage.removeItem("productId");
    localStorage.removeItem("__paypal_storage__");
    localStorage.removeItem("token");
    navigate("/");
  };

  useEffect(() => {
    let authToken = localStorage.getItem("Auth Token");
    setToken(authToken);
    // if (!authToken) {
    //   navigate('/')
    // }
    //check Role for Provider logic and Patient
    // var role = ''

    // Header sticky
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  ///// Header sticky
  const isSticky = (e) => {
    const header = document.querySelector(".header-section");
    const scrollTop = window.scrollY;
    scrollTop >= 100
      ? header?.classList?.add("sticky")
      : header?.classList?.remove("sticky");
  };

  return (
    <>
      <header className="header-section">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container">
            {/* <a className="navbar-brand logo" href="#"><img src={logo}/></a> */}
            <Link className="logo" to={!token ? "/" : "/app/lab-results"}>
              <img src={logo} alt="Header Provider Logo" />
            </Link>

            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink className="nav-link" to="/app/dashboard">
                    Lab Results
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink className="nav-link" to="/app/patient-profiles">
                    Patient Profiles
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink className="nav-link" to="/app/catalogs">
                    Catalogs
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink className="nav-link" to="/app/patient-order">
                    Patient Orders
                  </NavLink>
                </li>

                {decryptedData(localStorage.getItem("uR")) === "super-admin" ? (
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/app/admins-list">
                      Providers
                    </NavLink>
                  </li>
                ) : null}
              </ul>
            </div>

            {/* <div className='primary_btn'>
                  <button type="button" className="btn btn-transparent logout" onClick={handleLogout}>
                        <a className="btn_svg">
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                          <path d="M6.0536 9C6.0536 8.613 6.34805 8.307 6.72045 8.307H11.9167V3.996C11.9167 1.791 10.1933 0 8.08012 0H3.85386C1.73207 0 0 1.791 0 4.005V14.004C0 16.209 1.72341 18 3.8452 18H8.0628C10.1933 18 11.9167 16.2 11.9167 13.995V9.693H6.72045C6.34805 9.693 6.0536 9.378 6.0536 9"/>
                          <path d="M17.2301 8.55339L14.8614 6.18232C14.618 5.93788 14.2205 5.93788 13.9772 6.19047C13.7338 6.43491 13.7338 6.83416 13.9772 7.0786L15.2751 8.37414H11.9167V9.62893H15.2751L13.9772 10.9245C13.7338 11.1689 13.7338 11.5682 13.9772 11.8126C14.0988 11.9348 14.2611 12 14.4233 12C14.5775 12 14.7397 11.9348 14.8614 11.8126L17.2301 9.44153C17.3518 9.32745 17.4167 9.16449 17.4167 9.00153C17.4167 8.83043 17.3518 8.67561 17.2301 8.55339"/>
                          </svg>
                        </a>Log out
                  </button>
                </div> */}

            <div className="profile dropdown">
              <div
                className="profile_inner dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {/* <div className="profile_img">
                        <img src={profile} />
                    </div> */}
                <div className="profile_desc">
                  <div className="profile_name">
                    {decryptedData(localStorage.getItem("uFN"))}{" "}
                    {decryptedData(localStorage.getItem("uLN"))}
                  </div>
                  <div className="profile_desc">
                    {decryptedData(localStorage.getItem("uR"))}
                  </div>
                </div>
              </div>
              <ul className="dropdown-menu">
                <li>
                  <Link className="dropdown-item" to="/app/change-password">
                    Change Password
                  </Link>
                </li>
                <li>
                  <a className="dropdown-item" href="/" onClick={handleLogout}>
                    Log out
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
