import React, { useState } from "react";
// import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
// import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import useAlertUtils from "../../../../../utils/common.utils";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";

import { FaArrowLeft } from "react-icons/fa";
// import ReactPaginate from "react-paginate";
import { v4 as uuidv4 } from "uuid";
// import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
// import CancelIcon from "@mui/icons-material/Cancel";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import CreatePlan from "../CreatePlan/CreatePlan";
import EditPlan from "../EditPlan/EditPlan";
// import { styled } from "@mui/material/styles";
// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Switch from "@mui/material/Switch";
// import Switch, { SwitchProps } from "@mui/material/Switch";
// import Stack from "@mui/material/Stack";
// import Typography from "@mui/material/Typography";

import Layout from "../../../Layouts/layout";
import { fetchData } from "../../../../../services/apiService";

// const AntSwitch = styled(Switch)(({ theme }) => ({
//   width: 28,
//   height: 16,
//   padding: 0,
//   display: "flex",
//   "&:active": {
//     "& .MuiSwitch-thumb": {
//       width: 15,
//     },
//     "& .MuiSwitch-switchBase.Mui-checked": {
//       transform: "translateX(9px)",
//     },
//   },
//   "& .MuiSwitch-switchBase": {
//     padding: 2,
//     "&.Mui-checked": {
//       transform: "translateX(12px)",
//       color: "#fff",
//       "& + .MuiSwitch-track": {
//         opacity: 1,
//         backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
//       },
//     },
//   },
//   "& .MuiSwitch-thumb": {
//     boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
//     width: 12,
//     height: 12,
//     borderRadius: 6,
//     transition: theme.transitions.create(["width"], {
//       duration: 200,
//     }),
//   },
//   "& .MuiSwitch-track": {
//     borderRadius: 16 / 2,
//     opacity: 1,
//     backgroundColor:
//       theme.palette.mode === "dark"
//         ? "rgba(255,255,255,.35)"
//         : "rgba(0,0,0,.25)",
//     boxSizing: "border-box",
//   },
// }));

function ListPlans() {
  let params = useParams();
  // const initialized = useRef(false);
  // let navigate = useNavigate();
  // const [rows, setRows] = useState([]);
  const [pageLoader, setPageLoader] = useState(false);
  // const [totalPage, setTotalPage] = useState(1);
  const currentPage = 0;
  // const [currentPage, setCurrentPage] = useState(0);
  const [orderLIst, setOrderList] = useState([]);
  const [openCModal, setOpenCModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  // const [orderID, setOrderID] = useState(null);
  const orderType = null;
  const catalogID = null;
  const [editSelected, setEditSelected] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState("");
  const [planData, setPlanData] = useState({});
  // const [pageCount, setPageCount] = useState(1);
  const { alertSuccess, alertError } = useAlertUtils();
  //data variables
  // const [variant, setVariant] = useState();

  const [product, setProduct] = useState({});

  // const itemsPerPage = 10; // Number of items per page
  const toggle = () => setOpenCModal(!openCModal);
  const toggleCatalog = () => setOpenModal(!openModal);

  // const [variants, setVariants] = useState([]);

  const limit = 10;

  const closeBtn = (
    <button className="btn " onClick={toggle} type="button">
      &times;
    </button>
  );

  const closeCatalogBtn = (
    <button className="btn closeEditBtn" onClick={toggleCatalog} type="button">
      &times;
    </button>
  );

  const handleChangeList = (status) => {
    if (status) {
    }
  };

  const handleEditPan = (id, data) => {
    setOpenModal(true);
    setEditSelected(true);
    setSelectedPlanId(id);
    setPlanData(data);
  };

  // const handleModel = (orderID, type) => {
  //   setOrderID(orderID);
  //   setOrderType(type);
  //   setOpenCModal(true);
  // };

  const handleCreateModel = () => {
    setOpenModal(true);
    setEditSelected(false);
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString(); // Or use any other formatting method you prefer
  };

  // const handlePageClick = (selectedPage) => {
  //   setPageLoader(true);
  //   setCurrentPage(selectedPage.selected + 1);
  //   //  getPlans(selectedPage.selected+1);
  // };

  const closeCModal = () => {
    setOpenCModal(false);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const getProductDetails = async () => {
    try {
      let results = await fetchData("/api/catalog/" + params.productId);
      setProduct(results?.data);
    } catch (error) {
      alertError("Error Getting Details");
    }
  };

  const plans = async () => {
    try {
      let results = await fetchData(
        "/api/catalog/plans/all?page=" +
          currentPage +
          "&limit=" +
          limit +
          "&catalogId=" +
          params.productId
      );
      setOrderList(results?.data?.data);
    } catch (error) {
      alertError("Error Getting Plans");
    }
    // setPageCount(results?.data?.totalPages);
  };

  useEffect(() => {
    getProductDetails();
    plans();
  }, []);

  return (
    <>
      <Layout>
        <div className="main_content">
          <div className="container">
            <div className="back_link">
              <Link className="action_link" to="/app/catalogs">
                <FaArrowLeft />
                Catalogs
              </Link>
            </div>
            <div className="main_block">
              <div className="top_bar">
                <div className="left_part">
                  <div className="main_title">Plans List</div>
                </div>

                <div className="right_part">
                  <Button
                    type="button"
                    variant="contained"
                    color="error"
                    onClick={handleCreateModel}
                  >
                    Create Plan
                  </Button>
                </div>
              </div>

              <div className="bottom_bar">
                <div className="table_wrapper dashboard">
                  <div className="table_inner">
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>#Plan ID</TableCell>
                            <TableCell>Plan Name</TableCell>
                            <TableCell>Product Strength</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Interval Count</TableCell>
                            <TableCell>Interval Unit</TableCell>
                            <TableCell>Total Cycles</TableCell>
                            <TableCell> Date</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        {orderLIst?.length === 0 ? (
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell colSpan={10}>
                              <div className=" notice">
                                <div className="alert">
                                  <span className="alertText">
                                    {" "}
                                    No Plans Records Found
                                  </span>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                        <TableBody>
                          {orderLIst &&
                            orderLIst?.map((row, index) => (
                              <TableRow
                                key={uuidv4()}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell>
                                  <span className="mob_head">#Plan ID</span>
                                  {row?.paypalPlanId}
                                </TableCell>
                                <TableCell>
                                  <span className="mob_head">Plan Name</span>
                                  {row?.name}
                                </TableCell>
                                <TableCell>
                                  <span className="mob_head">
                                    Product Strength
                                  </span>
                                  {row?.variants?.strength}
                                </TableCell>
                                <TableCell>
                                  <span className="mob_head">Price</span>
                                  {row?.price}
                                </TableCell>
                                <TableCell>
                                  <span className="mob_head">
                                    Interval Count
                                  </span>
                                  {row?.interval_count}
                                </TableCell>
                                <TableCell>
                                  <span className="mob_head">
                                    Interval Unit
                                  </span>
                                  {row?.interval_unit}
                                </TableCell>
                                <TableCell>
                                  <span className="mob_head">Total Cycles</span>
                                  {row?.total_cycles}
                                </TableCell>
                                <TableCell>
                                  <span className="mob_head">Date</span>
                                  {formatTimestamp(row?.createdAt)}
                                </TableCell>
                                <TableCell>
                                  <span className="mob_head">Status</span>
                                  <span
                                    className={
                                      row?.status === "Active"
                                        ? "text-success"
                                        : "text-danger"
                                    }
                                  >
                                    {row?.status}
                                  </span>
                                </TableCell>
                                <TableCell>
                                  <span className="mob_head">Action</span>
                                  <Button
                                    variant="contained"
                                    size="small"
                                    onClick={() => {
                                      handleEditPan(row?.id, row);
                                    }}
                                  >
                                    Update
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>

      <Modal isOpen={openCModal} backdrop="static" toggle={closeCModal}>
        <ModalHeader toggle={toggle} close={closeBtn}>
          Confirmation
        </ModalHeader>

        <ModalBody>
          {orderType === "ca pture"
            ? " Are you sure you want to approve this order and continue payment capture process?"
            : "Are you sure you want to cancel this order?"}
        </ModalBody>
        <ModalFooter>
          {orderType === "capture" ? (
            <>
              <button className="btn btn-info text-light" disabled={pageLoader}>
                Approve
              </button>
            </>
          ) : (
            <>
              <button className="btn btn-info text-light" disabled={pageLoader}>
                Cancel Order
              </button>

              {pageLoader ? null : (
                <button
                  className="btn btn-danger text-light"
                  onClick={() => {
                    setOpenCModal(false);
                  }}
                >
                  close
                </button>
              )}
            </>
          )}
        </ModalFooter>
      </Modal>

      <Modal size="lg" isOpen={openModal} backdrop="static" toggle={closeModal}>
        <ModalHeader style={{ display: 'flex', justifyContent: 'space-between' }} toggle={toggleCatalog} close={closeCatalogBtn}>
          {editSelected ? "Edit Plan" : "Create Plan"}
        </ModalHeader>

        <ModalBody>
          {editSelected ? (
            <EditPlan
              loader={setPageLoader}
              modal={setOpenModal}
              product={product}
              catalogId={catalogID}
              callback={handleChangeList}
              planId={selectedPlanId}
              planData={planData}
              plans={plans}
            ></EditPlan>
          ) : (
            <CreatePlan
              loader={setPageLoader}
              modal={setOpenModal}
              product={product}
              catalogId={catalogID}
              callback={handleChangeList}
              plans={plans}
            ></CreatePlan>
          )}
        </ModalBody>
      </Modal>
    </>
  );
}

export default ListPlans;
