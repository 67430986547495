import { Link } from "react-router-dom";
import "./NotFound.scss";
import Footer from "../../components/Footer/Footer";
import HeaderProvider from "../../components/Header/HeaderProvider";
import Header from "../../components/Header/Header";
import PreLoginHeader from "../../components/PreLoginHeader/PreLoginHeader";

const NotFound = () => {
  const token = localStorage.getItem("token");
  let userRole = null;

  if (token) {
    try {
      const payload = JSON.parse(atob(token.split(".")[1]));
      userRole = payload.role;
    } catch (error) {
      console.error("Failed to decode token:", error);
    }
  }

  return (
    <>
      {!token ? (
        <div>
          <PreLoginHeader></PreLoginHeader>
        </div>
      ) : (
        <div>
          {userRole === 1 ? (
            <HeaderProvider></HeaderProvider>
          ) : userRole === 2 ? (
            <Header></Header>
          ) : null}
        </div>
      )}
      <div className="notfound-container">
        <h1 className="error-code">404</h1>
        <h2 className="error-message">Oops! Page Not Found</h2>
        <p className="error-description">
          The page you're looking for doesn't exist or has been moved.
        </p>

        <Link
          to={
            userRole === 1
              ? "/app/dashboard"
              : userRole === 2
              ? "/app/lab-results"
              : "/"
          }
          className="back-home-btn"
        >
          Go Home
        </Link>
      </div>
      <div>
        <Footer></Footer>
      </div>
    </>
  );
};

export default NotFound;
