import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
// import * as Yup from "yup";
import Layout from "../../Layouts/layout";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import Button from "@mui/material/Button";
import { uploadMultipartData } from "../../../../services/apiService";
// import { SubmitData } from "../../../../services/apiService";
import { catalogSchema } from "../../../../validations/validation";

import useAlertUtils from "../../../../utils/common.utils";

function CatalogCreate() {
  const navigate = useNavigate();
  const [variants, setVariants] = useState([]);
  const [newVariant, setNewVariant] = useState({
    strength: "",
    image: null,
    basePrice: "",
  });
  const { alertSuccess, alertError } = useAlertUtils();
  // Formik form setup
  const formik = useFormik({
    initialValues: {
      productName: "",
      description: "",
      basePrice: "",
      productImage: null,
    },
    validationSchema: catalogSchema,
    onSubmit: async (values, { resetForm }) => {
      // console.log(values)
      // console.log("Form submitted with values:", values, variants);
      const formData = new FormData();
      formData.append("productName", values?.productName);
      formData.append("description", values?.description);
      formData.append("basePrice", values?.basePrice);
      formData.append("productImage", values?.productImage);

      variants.forEach((variant, index) => {
        formData.append("variant_strength[]", variant?.strength);
        formData.append("variant_basePrice[]", variant?.basePrice);
        formData.append("variant_image", variant?.image);
      });

      try {
        const response = await uploadMultipartData(
          "/api/catalog/create",
          formData
        );

        if (response?.success) {
          resetForm();
          formik.setFieldValue("productImage", null);
          formik.setFieldValue("variantImage", null);
          setVariants([]);
          navigate("/app/catalogs");
          alertSuccess(response?.message);
        } else {
          alertError(response?.message);
        }
      } catch (error) {
        alertError(
          "An error occurred while creating the catalog. Please try again."
        );
      }
    },
  });

  const handleAddRow = () => {
    if (newVariant?.strength && newVariant?.basePrice && newVariant?.image) {
      setVariants([...variants, newVariant]);
      setNewVariant({ strength: "", image: null, basePrice: "" });
    } else {
      alertError("Please fill all fields for the variant.");
    }
  };

  const handleRemoveVariant = (index) => {
    setVariants(variants.filter((_, i) => i !== index));
  };

  return (
    <>
      <Layout>
        <div className="main_content">
          <div className="container">
            <div className="main_block">
              <div className="top_bar">
                <div className="left_part">
                  <div className="main_title">Create Catalog</div>
                </div>
              </div>

              <form
                onSubmit={formik.handleSubmit}
                encType="multipart/form-data"
              >
                <div className="form_field_wrapper">
                  <label>
                    Product Name<span className="req">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Product Name"
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    value={formik?.values?.productName}
                    className="form-control inp_text"
                    id="productName"
                  />
                  <p className="error">
                    {formik.errors.productName &&
                      formik.touched.productName &&
                      formik.errors.productName}
                  </p>
                </div>

                <div className="form_field_wrapper">
                  <label>
                    Description<span className="req">*</span>
                  </label>
                  <textarea
                    placeholder="Product Description"
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    value={formik?.values?.description}
                    className="form-control inp_text"
                    id="description"
                  />
                  <p className="error">
                    {formik?.errors?.description &&
                      formik?.touched?.description &&
                      formik?.errors?.description}
                  </p>
                </div>

                <div className="form_field_wrapper">
                  <label>
                    Base Price<span className="req">*</span>
                  </label>
                  <input
                    type="number"
                    placeholder="Base Price"
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    value={formik?.values?.basePrice}
                    className="form-control inp_text"
                    id="basePrice"
                  />
                  <p className="error">
                    {formik?.errors?.basePrice &&
                      formik?.touched?.basePrice &&
                      formik?.errors?.basePrice}
                  </p>
                </div>

                <div className="form_field_wrapper">
                  <label>
                    Product Image<span className="req">*</span>
                  </label>
                  <input
                    type="file"
                    accept="image/jpg, image/jpeg, image/png"
                    onChange={(event) => {
                      formik.setFieldValue(
                        "productImage",
                        event.currentTarget.files[0]
                      );
                    }}
                    onBlur={formik.handleBlur}
                    className="form-control"
                    id="productImage"
                  />
                  <p className="error">
                    {formik?.errors?.productImage &&
                      formik?.touched?.productImage &&
                      formik?.errors?.productImage}
                  </p>
                </div>

                <hr />

                {/* Add Variant Section */}
                <div className="add_variant_section">
                  <h3>Add Variant</h3>
                  <div className="form_field_wrapper">
                    <label>
                      Strength<span className="req">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Variant Strength"
                      value={newVariant?.strength}
                      onChange={(e) =>
                        setNewVariant({
                          ...newVariant,
                          strength: e.target.value,
                        })
                      }
                      className="form-control inp_text"
                    />
                  </div>
                  <div className="form_field_wrapper">
                    <label>
                      Variant Image<span className="req">*</span>
                    </label>
                    <input
                      type="file"
                      accept="image/jpg, image/jpeg, image/png"
                      onChange={(e) =>
                        setNewVariant({
                          ...newVariant,
                          image: e.target.files[0],
                        })
                      }
                      className="form-control"
                      name="variantImage"
                    />
                  </div>
                  <div className="form_field_wrapper">
                    <label>
                      Base Price<span className="req">*</span>
                    </label>
                    <input
                      type="number"
                      placeholder="Variant Base Price"
                      value={newVariant.basePrice}
                      onChange={(e) =>
                        setNewVariant({
                          ...newVariant,
                          basePrice: e.target.value,
                        })
                      }
                      className="form-control inp_text"
                    />
                  </div>
                  <button
                    type="button"
                    onClick={handleAddRow}
                    className="btn btn-primary "
                  >
                    Add Variant
                  </button>
                </div>
                <hr></hr>
                {/* Variants Table */}
                {variants.length > 0 && (
                  <TableContainer
                    component={Paper}
                    className="variant_table_container"
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Strength</TableCell>
                          <TableCell>Product Image</TableCell>
                          <TableCell>Base Price</TableCell>
                          <TableCell>Remove</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {variants &&
                          variants.map((variant, index) => (
                            <TableRow key={index}>
                              <TableCell>{variant?.strength}</TableCell>
                              <TableCell>
                                <img
                                  src={URL.createObjectURL(variant?.image)}
                                  height={200}
                                  width=""
                                  alt="variant"
                                  className="variant-image"
                                />
                              </TableCell>
                              <TableCell>{variant?.basePrice}</TableCell>
                              <TableCell>
                                <IconButton
                                  onClick={() => handleRemoveVariant(index)}
                                  aria-label="remove"
                                >
                                  <CloseIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}

                <hr />
                <div className="primary_btn">
                  <button type="submit" className="btn btn-secondary">
                    NEXT
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default CatalogCreate;
