import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { SubmitData } from '../../../../../services/apiService';
import useAlertUtils from '../../../../../utils/common.utils';

// Corrected validation schema with all fields
const validationSchema = Yup.object({
  productName: Yup.string().required('Product Name is required'),
  name: Yup.string().required('Plan Name is required'),
  description: Yup.string().required('Plan Description is required'),
  price: Yup.number().required('Plan Price is required').typeError('Plan Price must be a number'),
  status: Yup.string().required('Plan Status is required')
});

const CreateCatalog = ({ loader, modal, product, catalogId, callback, planId, planData, plans }) => {
  const { alertSuccess, alertError } = useAlertUtils();

  return (
    <div className='main_content product_details'>
      <div className='container'>
        <div className='my-2 mx-1'>
          <div className='col-lg-12 part left_part'>
            <Formik
              initialValues={{
                productName: product?.productName,
                name: planData?.name,
                strength:planData?.variants?.strength,
                description:planData?.description,
                price: planData?.price,
                status: 'Active',
                planId: planData?.paypalPlanId
              }}

              validationSchema={validationSchema}
              onSubmit={async(values, { resetForm }) => {
                try {
                  const response = await SubmitData(`/api/catalog/plans/update/${planId}`, values);
                  if(response?.success === true){
                    alertSuccess(response?.message);
                  }else{
                    alertError(response?.message);
                  }
                } catch (error) {
                  alertError('An error occurred while submitting the form.'); // Handle the error
                }
                modal(false);
                resetForm();
                plans();
              }}
            >
              {() => (
                <Form>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form_field_wrapper'>
                        <label>Product Name</label>
                        <Field
                          type='text'
                          placeholder='Product Name'
                          className='form-control inp_text'
                          name='productName'
                          readOnly
                        />
                        <ErrorMessage name='productName' component='div' className='error' />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form_field_wrapper'>
                        <label>Strength</label>
                        <Field
                          type='text'
                          placeholder='Strength'
                          name='strength' // Updated field name for strength if needed
                          className='form-control inp_text'
                          value='Sample Strength'
                          readOnly
                        />
                      </div>
                    </div>


                       <Field type="hidden"  value={planData?.planPaypalId} name="planId"/>
                    <div className='col-md-12'>
                      <div className='form_field_wrapper'>
                        <label>Plan Name</label>
                        <Field
                          type='text'
                          placeholder='Plan Name'
                          className='form-control inp_text'
                          name='name'
                        />
                        <ErrorMessage name='name' component='div' className='error' />
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='form_field_wrapper'>
                        <label>Plan Description</label>
                        <Field
                          as='textarea'
                          className='form-control line_wrap'
                          id='description'
                          name='description'
                          rows='3'
                        />
                        <ErrorMessage name='description' component='div' className='error' />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form_field_wrapper'>
                        <label>Plan Price</label>
                        <Field
                          type='text'
                          placeholder='Plan Price'
                          className='form-control inp_text'
                          name='price'
                        />
                        <ErrorMessage name='price' component='div' className='error' />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form_field_wrapper'>
                        <label>Plan Status</label>
                        <Field
                          as='select'
                          aria-label='Default form-control select example'
                          name='status'
                        >
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </Field>
                        <ErrorMessage name='status' component='div' className='error' />
                      </div>
                    </div>
                  </div>
                  <button type='submit' className='btn btn-primary btn_submit'>
                    Save
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCatalog;
