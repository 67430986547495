import React, { useEffect, useState } from "react";
import Layout from "../Layouts/layout";
import { Button, TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Stack } from "@mui/material";
import {
  makeEncryptionData,
  decryptedData,
} from "../../../services/encryptionDecription.service";
import { SubmitData, fetchData } from "../../../services/apiService";
import useAlertUtils from "../../../utils/common.utils";

function Admins() {
  const [openModel, setOpenModel] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const { alertSuccess, alertError } = useAlertUtils();
  const [adminsData, setAdminsData] = useState(null);

  const getAdminsData = async () => {
    const response = await fetchData("/api/users/get-admins");
    setAdminsData(response.data);
  };

  const toggleCatalog = () => setOpenModel(!openModel);

  const handleCreateAdmin = () => {
    setOpenModel(true);
  };

  const closeModel = () => {
    setOpenModel(false);
  };

  const closeCatalogBtn = (
    <button className="btn closeEditBtn" onClick={toggleCatalog} type="button">
      &times;
    </button>
  );

  const generateRandomPassword = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let password = "";
    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      password += characters[randomIndex];
    }
    return password;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const adminData = {
      firstName,
      lastName,
      email,
      password: generateRandomPassword(),
    };

    try {
      const encryptedData = makeEncryptionData(adminData);
      encryptedData.role = 1;
      encryptedData.agreeTerms = 1;

      if (encryptedData) {
        const response = await SubmitData(
          "/api/users/register-admin",
          encryptedData
        );
        if (response?.success) {
          alertSuccess(
            "A verification email has been sent to Admin's registered email"
          );
          setFirstName("");
          setLastName("");
          setEmail("");
          closeModel();
          getAdminsData();
        } else {
          alertError(response?.message);
        }
      }
    } catch (error) {
      alertError(
        "An error occurred while Admin's Registeration. Please try again."
      );
    }
  };

  useEffect(() => {
    getAdminsData();
  }, []);

  console.log("ADMIN'S DATA --> ", adminsData);

  return (
    <>
      <Layout>
        <div className="main_content">
          <div className="container">
            <div className="main_block">
              <div className="top_bar">
                <div className="left_part">
                  <div className="main_title">Admins</div>
                </div>
                <div className="right_part">
                  <div className="right_part_inner">
                    <button
                      onClick={handleCreateAdmin}
                      className="btn btn-primary add_kit"
                    >
                      Create Admin
                    </button>
                  </div>
                </div>
              </div>
              <div className="table_wrapper dashboard">
                <div className="table_inner">
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="right">First Name</TableCell>
                          <TableCell align="right">Last Name</TableCell>
                          <TableCell align="right">Email</TableCell>
                          <TableCell align="right">Status</TableCell>
                          {/* <TableCell align="right">Actions</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {adminsData &&
                          adminsData?.map((row) => (
                            <TableRow
                              key={row?.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="right">
                                <span className="mob_head">First Name</span>
                                {decryptedData(row?.firstName)}
                              </TableCell>
                              <TableCell align="right">
                                <span className="mob_head">Last Name</span>
                                {decryptedData(row?.lastName)}
                              </TableCell>

                              <TableCell align="right">
                                <span className="mob_head">Email</span>
                                {decryptedData(row?.email)}
                              </TableCell>

                              <TableCell align="right">
                                <span className="mob_head">Status</span>
                                <span
                                  style={{
                                    display: "inline-block",
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                    backgroundColor:
                                      row?.verifyStatus === 1 ? "green" : "red",
                                    color: "white",
                                  }}
                                >
                                  {row?.verifyStatus === 1
                                    ? "Verified"
                                    : "Unverified"}
                                </span>
                              </TableCell>

                              {/* <TableCell align="right">
                                <span className="mob_head">Action</span>
                                <button>Delete</button>
                              </TableCell> */}
                            </TableRow>
                          ))}

                        {adminsData && adminsData.length === 0 ? (
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell colSpan={7}>
                              <div className="notice">
                                <div className="alert">
                                  <span className="alertText">
                                    No Records Found button
                                  </span>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        ) : null}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      <Modal size="lg" isOpen={openModel} backdrop="static" toggle={closeModel}>
        <ModalHeader
          style={{ display: "flex", justifyContent: "space-between" }}
          toggle={toggleCatalog}
          close={closeCatalogBtn}
        >
          Create Provider
        </ModalHeader>

        <ModalBody>
          <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <TextField
                label="First Name"
                variant="outlined"
                fullWidth
                required
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <TextField
                label="Last Name"
                variant="outlined"
                fullWidth
                required
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              <TextField
                label="Email"
                variant="outlined"
                type="email"
                fullWidth
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                type="submit"
                variant="contained"
                color="error"
                size="large"
              >
                Create Provider
              </Button>
            </Stack>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
}

export default Admins;
