import { Component } from "react";
import "./ErrorBoundary.scss";
import Footer from "../../components/Footer/Footer";
import PreLoginHeader from "../../components/PreLoginHeader/PreLoginHeader";
import HeaderProvider from "../../components/Header/HeaderProvider";
import Header from "../../components/Header/Header";
import { Link } from "react-router-dom";

class ErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
    console.error("Error caught by Error Boundary:", error, errorInfo);
  }

  render() {
    const token = localStorage.getItem("token");
    let userRole = null;

    if (token) {
      try {
        const payload = JSON.parse(atob(token.split(".")[1]));
        userRole = payload.role;
      } catch (error) {
        console.error("Failed to decode token:", error);
      }
    }
    if (this.state.hasError) {
      const overlay = document.getElementById(
        "webpack-dev-server-client-overlay"
      );
      if (overlay) {
        overlay.style.display = "none";
      }
      window.scrollTo(0, 0);

      const handleClick = () => {
        this.setState({ hasError: false });
      };

      return (
        <>
          {!token ? (
            <div>
              <PreLoginHeader></PreLoginHeader>
            </div>
          ) : (
            <div>
              {userRole === 1 ? (
                <HeaderProvider></HeaderProvider>
              ) : userRole === 2 ? (
                <Header></Header>
              ) : null}
            </div>
          )}
          <div className="error-container">
            <div className="error-content">
              <div className="error-icon">⚠️</div>
              <h1 className="error-title">Something went wrong!</h1>
              <p className="error-description">
                Please try again later or contact support.
              </p>
              <Link
                to={
                  userRole === 1
                    ? "/app/dashboard"
                    : userRole === 2
                    ? "/app/lab-results"
                    : "/"
                }
                onClick={handleClick}
                className="back-home-btn"
              >
                Go Home
              </Link>
            </div>
          </div>
          <div>
            <Footer></Footer>
          </div>
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
